import * as process from "process";
import dayjs from "dayjs";
import fs from "fs";
import type { GetStaticPropsResult, NextPage } from "next";
import path from "path";
import { createContext, useContext, useEffect } from "react";

import PageHead from "~/components/common/PageHead";
import MobilePage from "~/components/home/page/index/MobilePage";
import PcPage from "~/components/home/page/index/PcPage";
import { Props } from "~/components/home/page/index/lib";
import useIsSpMode from "~/lib/client/hooks/useIsSpMode";
import useLayout from "~/lib/client/hooks/useLayout";
import { useActivityLogger } from "~/lib/client/logger/useActivityLogger";
import { createGraphqlClient } from "~/lib/graphql/graphqlClient";
import "~/lib/monkeypatch";
import {
  isPublishedWithinAWeek,
  separateMarkdownFrontMatter,
} from "~/lib/server/MarkdownHelper";
import { fetchAchievements } from "~/lib/server/microcms";

export async function getStaticProps(): Promise<GetStaticPropsResult<Props>> {
  const graphqlClient = createGraphqlClient();
  const { categories, fussyEventReferences, discoveryAds } =
    await graphqlClient.topContent();

  const files = fs
    .readdirSync(path.join(process.cwd(), "data/info"))
    .map((filepath) => path.join(process.cwd(), "data/info", filepath));

  const infoMetaList = files
    .map(separateMarkdownFrontMatter)
    .filter(({ metadata }) => isPublishedWithinAWeek(metadata))
    .slice()
    .sort((a, b) => {
      const aDate = new Date(a.metadata.date || "");
      const bDate = new Date(b.metadata.date || "");
      return bDate.getTime() - aDate.getTime();
    });
  const latestInfo = infoMetaList.length == 0 ? null : infoMetaList[0];
  const sortedDiscoveryAds = discoveryAds.sort(
    (a, b) => a.position - b.position
  );
  const achievements = (await fetchAchievements()).sort((a, b) =>
    b.publishedAt.localeCompare(a.publishedAt)
  );
  return {
    props: {
      categories: categories.shuffle(),
      info: !!latestInfo
        ? {
            title: latestInfo.metadata.title,
            url: latestInfo.metadata.url,
            date: latestInfo.metadata.date,
          }
        : null,
      fussyEventReferences: fussyEventReferences.sort((a, b) =>
        dayjs(b.eventAt).diff(dayjs(a.eventAt))
      ),
      discoveryAds: sortedDiscoveryAds,
      achievements,
    },
    revalidate: 60 * 60, // 1 hour
  };
}

const DiscoveryContext = createContext(
  {} as {
    props: Props;
  }
);

export const useDiscoveryContext = () => useContext(DiscoveryContext);

const pageName = "root" as const;

const Page: NextPage<Props> = (props) => {
  const isSpMode = useIsSpMode();
  const Layout = useLayout({
    layoutType: isSpMode ? "SpPageRootLayout" : "PcPageRootLayout",
  });
  const Page = isSpMode ? MobilePage : PcPage;
  const logger = useActivityLogger();

  useEffect(() => {
    const params = {
      actionName: "show",
      pageName,
    } as const;

    if (!logger) return;
    if (isSpMode === undefined) return;

    (async () => {
      await logger(params);
    })();
  }, [logger, isSpMode]);

  if (isSpMode === undefined) return null;

  return (
    <DiscoveryContext.Provider
      value={{
        props,
      }}
    >
      <PageHead />
      <Layout>
        <Page />
      </Layout>
    </DiscoveryContext.Provider>
  );
};

export default Page;
